import axios from 'axios'

class student_bills_payment_api {
  async get({ invoice_id, study_year }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `/counting/student_salary/payment?invoice_id=${invoice_id}&study_year=${study_year}`,
    )

    return response
  }

  async add({ invoice_id, payments }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('/counting/student_salary/payment', {
      invoice_id,
      payments,
    })

    return response
  }

  async addOne({ invoice_id, payment }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('/counting/student_salary/payment/one', {
      invoice_id,
      payment,
    })

    return response
  }

  async edit({ invoice_id, id, amount, next_payment, type, desc }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.put('/counting/student_salary/payment', {
      invoice_id,
      id,
      amount,
      type,
      next_payment,
      desc,
    })

    return response
  }

  async remove({ payment_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`/counting/student_salary/payment/id/${payment_id}`)

    return response
  }
}

export default new student_bills_payment_api()
